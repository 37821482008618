export enum RoleCodes {
	None = "geen",

	// Can access applications and information
	UserRole = "user",

	// Can do everything for the current tenant
	// Can manage users
	// Can manage settings
	// Can manage lookups
	// Can manage audiences
	AdministratorRole = "admin",

	// Can add and manage events in One for Events
	EventManagerRole = "eventmanager",

	// Can add and manage internal vacancies using One for You.
	VacancyManagerRole = "vacancymanager",

	// Can add and manage addresses and locations using One for You.
	AddressManagerRole = "addressmanager",

	// Can add and manage shortcuts using One for You.
	ShortcutManagerRole = "shortcutmanager",

	// Can add and manage announcement using One for You.
	AnnouncementManagerRole = "announcementmanager",

	// Can add and manage social settings using One for Admins
	SocialManagerRole = "socialmanager",

	// Can pin news items.
	NewsManagerRole = "newsmanager",

	// Can see usage information using One for Admins
	UsageReportingRole = "usagereporting",

	// Can approve teams in One for Teams.
	TeamManagerRole = "teammanager",

	// Can highlight quality subjects/pages.
	QualityManagerRole = "qualitymanager",

	// Can approve teams in One for Teams.
	StartupMessageManagerRole = "startupmessagemanager",

	// Can edit the taxonomy in One for Admins.
	TaxonomyManager = "taxonomymanager",
}

export enum RoleNames {
	// Can access applications and information
	UserRole = "User",

	// Can do everything for the current tenant
	// Can manage users
	// Can manage settings
	// Can manage lookups
	// Can manage audiences
	AdministratorRole = "Administrator",

	// Can add and manage events in One for Events
	EventManagerRole = "Event Manager",

	// Can add and manage internal vacancies using One for You.
	VacancyManagerRole = "Vacancy Manager",

	// Can add and manage addresses and locations using One for You.
	AddressManagerRole = "Address Manager",

	// Can add and manage shortcuts using One for You.
	ShortcutManagerRole = "Shortcut Manager",

	// Can add and manage announcement using One for You.
	AnnouncementManagerRole = "Announcement Manager",

	// Can add and manage social settings using One for Admins
	SocialManagerRole = "Social Manager",

	// Can pin news items.
	NewsManagerRole = "News Manager",

	// Can see usage information using One for Admins
	UsageReportingRole = "Usage Reporting",

	// Can highlight quality subjects/pages.
	QualityManagerRole = "Quality Manager",

	// Can approve teams in One for Teams.
	TeamsManagerRole = "Teams Manager",

	// Can edit the taxonomy in One for Admins.
	TaxonomyManagerRole = "Taxonomy Manager",
}
