export enum CustomLogEvent {
	// NPM package
	SubscriptionValidatorTimeout = "Subscription Validator Timeout",
	SubscriptionValidatorCheckError = "Subscription Validator Error",

	// Communication
	NewsItemPinned = "Newsitem Pinned",

	// Utilities
	BookARoom = "BookaRoom",

	// Ilionx
	SecurityAwarenessClicked = "OpenSecurityAwarenessGame",

	// OQM & One for Meda
	Search = "Search",

	// OQM
	ViewSubject = "View Subject",
	ViewSearchResult = "View search result",
	ViewRelatedFile = "View related file",

	// One for Media
	ViewItem = "View media item",
	DownloadItem = "Download media item",
	AddToCart = "Add item to cart",

	//Announcements
	AnnouncementViewed = "Announcement Viewed",

	// One for Events
	EventViewed = "Event viewed",
	EventCreated = "Event created",
	EventRegistrationCreated = "Event registration created",
	EventRegistrationCanceled = "Event registration canceled",
}
